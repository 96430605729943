import { FC } from 'react';

const TableHeader: FC<any> = ({ localFilter, changeFilter }) => {
	const filterActiveUp = (text: string) => {
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p>Timestamp</p>
				</div>
				<div className="td">
					<p>Account ID</p>
				</div>
				<div className="td">
					<p>Type</p>
				</div>
				<div className="td">
					<p>Currency</p>
				</div>
				<div className="td">
					<p>Debit</p>
				</div>
				<div className="td">
					<p>Credit</p>
				</div>
				<div className="td">
					{/* <button
						onClick={() => {
							changeFilter('fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Fee
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('fee')}`} />
							<div className={`arrow-top ${filterActiveUp('fee')}`} />
						</span>
					</button> */}
					<p>Fee</p>
				</div>
				<div className="td">
					<p>Credit Fee</p>
				</div>
				<div className="td">
					<p>Debit Fee</p>
				</div>
				<div className="td">
					{/* <button
						onClick={() => {
							changeFilter('net');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Net
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('net')}`} />
							<div className={`arrow-top ${filterActiveUp('net')}`} />
						</span>
					</button> */}
					<p>Net</p>
				</div>
				<div className="td">
					<p>Balance</p>
				</div>
				<div className="td">
					<p>Destination</p>
				</div>
				<div className="td">
					<p>Reference</p>
				</div>
				<div className="td">
					<p className="justify-content-center">Tx ID</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
